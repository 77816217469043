import React, { useState } from "react";
import Input from "../facultydashboard/formGroup/Input";
import Textarea from "../facultydashboard/formGroup/Textarea";
import axios from 'axios'
import { message } from "antd";
import Spinner from "../facultydashboard/formGroup/Spinner";
import { Widget } from "@uploadcare/react-widget";
import { useNavigate } from "react-router-dom";


function AlumniForm() {
  const [loader ,setLoader] = useState(false)
  const [thankyou ,setThankyou] = useState(false)
  const navigate = useNavigate()
  const intialValue = {
    name: "",
    email: "",
    dob: "",
    mobileNo: "",
    session: "",
    pic: "",
    registrationNo: "",
    yearOfRegistration: "",
    degreeYear: "",
    yearOfAdmission: "",
    presentDesignation: "",
  };
  const [state, setState] = useState(intialValue);
  const [course, setCourse] = useState("");
  const [gender, setGender] = useState("");
  const [presentStatus, setPresentStatus] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [photo, setPhoto] = useState("");
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    // setState({ [i.target.name]: "" })
    e.preventDefault()

      addAlumni()

}
console.log(gender)
  const addAlumni = () => {
    setLoader(true)
    axios.post('/api/v1/almuni/registration-form', {
        name: state.name,
        gender:gender,
        mobileNo: state.mobileNo,
        email: state.email,
        dob: state.dob,
        session: state.session,
        program: course,
        yearOfAdmission: state.yearOfAdmission,
        yearOfDegree: state.degreeYear,
        registrationNo: state.registrationNo,
        yearOfRegistration: state.yearOfRegistration,
        presentDesignation: state.presentDesignation,
        presentOrganization: presentStatus,
        residentOrganization: mailingAddress,
        photo

    })
        .then(function (response) {
            // console.log(response);
            setLoader(false)
            message.success(response.data.message)
            setState({ ...intialValue })
            setPhoto("")
            setCourse("")
            setPresentStatus("")
            setMailingAddress("")
            navigate("/thank-you")

        })
        .catch(function (error) {
            console.log(error);
            setLoader(false)
            message.error(error.response.data.errors)
        });
}
  return (
    <>
     {
      loader && <Spinner/>
    }

    <div className="">

      <div className="grid z-50 grid-cols-1 mt-8 gap-y-2 justify-items-center my-auto">
        <div className="bg-white  font-bold  rounded-lg w-5/6 mx-4 shadow  px-4 py-2 ">
          <h1 className="text-4xl  text-center mb-2">
            B. N. Mandal University Madhepura <br/> बी. एन. मंडल विश्वविद्यालय,
            मधेपुरा
          </h1>
          <h1 className="text-3xl  text-center mb-2">
            Alumni Registration Form, Maithili West Campus, BNMU
          </h1>
        </div>
        <div></div>
        <div className="bg-white tst rounded-lg w-5/6 mx-4 shadow  px-4 py-2 ">
          <form
            onSubmit={onSubmit}
            className="w-5/6 grid mx-auto my-auto items-center md:grid-cols-3 gap-x-4 gap-y-2 "
          >
            <Input
              label="Name"
              name="name"
              man="*"
              type="text"
              value={state.name}
              onChange={onChange}
              placeholder=""
            />
            <div>
              <label for="gender">
                Gender
                <span className="text-red-500 font-bold text-[1.2rem]">*</span>
              </label>
              <select
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                id="category"
                class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
              >
                <option className="" value="">
                  -----Select-----
                </option>
                <option className="" value="Male">
                  Male
                </option>
                {/* <option className="" value='1'>Admin</option> */}
                <option className="" value="Female">
                  Female
                </option>
              </select>
            </div>
            <Input
              label="Date of Birth"
              man="*"
              name="dob"
              type="date"
              value={state.dob}
              onChange={onChange}
              placeholder=""
            />
            <Input
              label="Mobile No."
              man="*"
              name="mobileNo"
              type="number"
              value={state.mobileNo}
              onChange={onChange}
              placeholder=""
            />
            <Input
              label="Email"
              name="email"
              man="*"
              type="email"
              value={state.email}
              onChange={onChange}
              placeholder=""
            />

            <div>
              <label for="category">
                Course/Program
                <span className="text-red-500 font-bold text-[1.2rem]">*</span>
              </label>
              <select
                value={course}
                onChange={(e) => {
                  setCourse(e.target.value);
                }}
                id="category"
                class={`w-full h-9  rounded-sm border focus:border-gray-700  text-sm focus:outline-none focus:bg-white bg-gray-200 `}
              >
                <option className="" value="">
                  -----Select-----
                </option>
                <option className="" value="PG">
                  PG
                </option>
                {/* <option className="" value='1'>Admin</option> */}
                <option className="" value="Ph.D">
                  Ph.D
                </option>
              </select>
            </div>
            <Input
              label="Session(if pg is chosen)"
              name="session"
              man=""
              placeholder="2016-2018"
              type="text"
              value={state.session}
              onChange={onChange}
            />
            <Input
              label="Year of Admission(if pg is chosen)"
              name="yearOfAdmission"
              man=""
              placeholder="2016"
              type="text"
              value={state.yearOfAdmission}
              onChange={onChange}
            />
            <Input
              label="Year of Degree Awarded(if pg is chosen)"
              name="degreeYear"
              man=""
              placeholder="2018"
              type="text"
              value={state.degreeYear}
              onChange={onChange}
            />
            <Input
              label="Registration No."
              name="registrationNo"
              man="*"
              type="text"
              value={state.registrationNo}
              onChange={onChange}
              placeholder=""
            />

            <Input
              label="Year of Registration"
              name="yearOfRegistration"
              man="*"
              type="text"
              value={state.yearOfRegistration}
              onChange={onChange}
              placeholder=""
            />
            <Input
              label="Present Designation"
              name="presentDesignation"
              man="*"
              type="text"
              value={state.presentDesignation}
              onChange={onChange}
              placeholder=""
            />

            <div className="col-span-full">
              <Textarea
                label="Full address of the present organization"
                placeholder=""
                row="4"
                value={presentStatus}
                onChange={(e) => setPresentStatus(e.target.value)}
              />
            </div>
            <div className="col-span-full">
              <Textarea
                label="Mailing Address or Residential  Address "
                placeholder=""
                row="4"
                value={mailingAddress}
                onChange={(e) => setMailingAddress(e.target.value)}
              />
            </div>
            <div className="mt-4">
                <p className="mb-2 text-sm font-medium text-gray-900">Upload your image <span className='text-red-500 font-bold text-[1.2rem]'>*</span></p>
            <div className='bg-[#e5e7eb] text-black-500 mt-0'>
                        <Widget
                            publicKey="1e63758e5c94313da33d"
                            onChange={info => setPhoto(info.cdnUrl)}
                        />
                    </div>
</div>

                    {photo && (
                        <img src={photo} alt="Uploaded file" width="100" />
                    )}
            <div className="col-span-full">
              <div className="flex justify-center mt-2">
                <button className="mt-3 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  );
}

export default AlumniForm;
